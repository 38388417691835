import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Accordion, Card } from 'react-bootstrap';

import { getCurrentUser } from 'aws-amplify/auth';
import { getSettingsForUser, createAliasForUser, deleteUser } from '../utils/AdminHandler';
import { ErrorContext } from '../../components/Common/ErrorHandler';
import { updateAliasForUser, deleteAliasForUser, getSearchablesEligibleForRightClickMenu, getUsersRightClickMenuSetting, updateUsersRightClickMenuSetting } from '../utils/links_handler';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import  './SettingsPage.css';

function SettingsPage(props) {
    const [newAlias, setNewAlias] = useState('');
    const [hasAlias, setHasAlias] = useState(false);
    const [currentAlias, setCurrentAlias] = useState('');
    const [rightClickMenuSettings, setRightClickMenuSettings] = useState([]);
    const [newRightClickMenuSettings, setNewRightClickMenuSettings] = useState([]);
    const [searchablesEligibleForRightClickMenu, setSearchablesEligibleForRightClickMenu] = useState([]);
    const [currentUsername, setCurrentUsername] = useState('');
    

    const navigate = useNavigate();

    const { setError } = useContext(ErrorContext);

    useEffect(() => {
        async function checkUser() {
            const user = await getCurrentUser();
            if (!user) {
                navigate('/');
            }
        }
        checkUser();
    }, []);

    const handleCreate = async () => {
        // Implement your alias creation or update logic here
        try {
            const user = await getCurrentUser();
            const result = await createAliasForUser(user.username, newAlias, setError);
            console.log(result);
        }
        catch (error) {
            console.error('Failed to create alias: ', error);
            // setError('Failed to create alias. Please try again.');
        }
        console.log('Alias submitted:', newAlias);
    };

    const handleDelete = async () => {
        // Implement your alias deletion logic here
        try {
            const user = await getCurrentUser();
            deleteAliasForUser(user.username, currentAlias, setError);
        }
        catch (error) {
            console.error('Failed to delete alias: ', error);
            // setError('Failed to delete alias. Please try again.');
        }

        console.log('Alias deleted');
    };

    const handleUpdate = async (event) => {
        // Implement your alias deletion logic here
        try {
            event.preventDefault();
            const user = await getCurrentUser();
            const result = await updateAliasForUser(user.username, currentAlias, newAlias, setError);
            console.log('Alias updated');
            console.log(result);
            window.location.reload();
        }
        catch (error) {
            console.error('Failed to update alias: ', error);
            // setError('Failed to update alias. Please try again.');
        }
        
    };

    const handleDeleteAccount = async (event) => {
        try {
            event.preventDefault();
            const user = await getCurrentUser();
            const result = await deleteUser(user.username);
            console.log('Account deleted');
            console.log(result);
        } catch (error) {
            console.error('Failed to delete account: ', error);
            // setError('Failed to delete account. Please try again.');
        }
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                source.droppableId === 'droppable1' ? newRightClickMenuSettings : searchablesEligibleForRightClickMenu,
                source.index,
                destination.index
            );

            if (source.droppableId === 'droppable1') {
                setNewRightClickMenuSettings(items);
            } else {
                setSearchablesEligibleForRightClickMenu(items);
            }
        } else {
            const result = move(
                source.droppableId === 'droppable1' ? newRightClickMenuSettings : searchablesEligibleForRightClickMenu,
                source.droppableId === 'droppable1' ? searchablesEligibleForRightClickMenu : newRightClickMenuSettings,
                source,
                destination
            );

            setNewRightClickMenuSettings(result.droppable1);
            setSearchablesEligibleForRightClickMenu(result.droppable2);
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    const handleSave = () => {
        // Reformat the data to the expected format
        const formattedData = JSON.stringify(newRightClickMenuSettings.map(item => [item[0], item[1]]));
        
        // Save the current settings
        console.log('Settings saved:', formattedData);
        
        // Call updateUsersRightClickMenuSetting
        updateUsersRightClickMenuSetting(currentUsername, formattedData);
        
        window.location.reload();
    };

    const isSaveButtonDisabled = JSON.stringify(newRightClickMenuSettings) === JSON.stringify(rightClickMenuSettings);

    useEffect(() => {
        async function checkUser() {
            const user = await getCurrentUser();
            if (!user) {
                navigate('/');
            } else {
                setCurrentUsername(user.username);
                const [settings, rightClickMenuSettings, searchablesEligibleForRightClickMenu] = await Promise.all([
                    getSettingsForUser(user.username),
                    getUsersRightClickMenuSetting(user.username),
                    getSearchablesEligibleForRightClickMenu(user.username)
                  ]);
                console.log(settings);
                if (settings && settings.has_alias) {
                    setHasAlias(true);
                    setCurrentAlias(settings.alias);
                }
                if (rightClickMenuSettings) {
                    setRightClickMenuSettings(rightClickMenuSettings);
                    setNewRightClickMenuSettings(rightClickMenuSettings);
                }
                if (searchablesEligibleForRightClickMenu) {
                    setSearchablesEligibleForRightClickMenu(searchablesEligibleForRightClickMenu);
                }
            }
        }
        checkUser();
    }, []);

    return (
        <Container>
            <Row className="justify-content-md-center my-5">
                <Col xs={12} md={8}>
                    <h2 style={{ marginBottom: '50px' }}>Settings</h2>
                    <Accordion defaultActiveKey="0">
                        {!hasAlias ? (
                            <Accordion>
                                <Card>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <strong>Create Alias</strong>
                                        </Accordion.Header>
                                        <Accordion.Body as={Card.Body}>
                                            <p style={{ fontSize: '18px' }}>Create an alias to make your links publicly available.</p>
                                            <p style={{ fontSize: '16px' }}>For instance, if your alias is 'blank', your links can be reached at urlinks.me/u/blank/link.</p>
                                            <p>Alias Rules:</p>
                                            <ul>
                                                <li>Must be at least 5 characters.</li>
                                                <li>Can only contain alphanumeric characters.</li>
                                                <li>Case insensitive.</li>
                                                <li>Must be unique.</li>
                                            </ul>
                                            <Form onSubmit={handleCreate}>
                                                <Form.Group controlId="formAlias">
                                                    <Form.Label>Alias</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter alias"
                                                        value={newAlias}
                                                        onChange={(e) => setNewAlias(e.target.value)}
                                                    />
                                                </Form.Group>
                                                <Button variant="primary" type="submit">
                                                    Create Alias
                                                </Button>
                                            </Form>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Card>
                            </Accordion>
                        ) : (
                            <>
                                <Card>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>
                                                <strong>Update Alias</strong>
                                            </Accordion.Header>
                                            <Accordion.Body as={Card.Body}>
                                                <Form onSubmit={handleUpdate}>
                                                    <Form.Group controlId="formAlias">
                                                        <p>Alias Rules:</p>
                                                        <ul>
                                                            <li>Must be at least 5 characters.</li>
                                                            <li>Can only contain alphanumeric characters.</li>
                                                            <li>Case insensitive.</li>
                                                            <li>Must be unique.</li>
                                                        </ul>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={`Enter new alias, your current alias is: "${currentAlias}"`}
                                                            value={newAlias}
                                                            onChange={(e) => setNewAlias(e.target.value)}
                                                        />
                                                    </Form.Group>
                                                    <Button variant="primary" type="submit">
                                                        Update Alias
                                                    </Button>
                                                </Form>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Card>
                                <Card className="mt-3">
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><strong>Delete Alias</strong></Accordion.Header>
                                        <Accordion.Body>
                                            <h4 className="font-weight-bold">This action cannot be undone.</h4>
                                            <p>This will mark all of your public shortlinks as private.</p>
                                            <Button variant="danger" onClick={handleDelete}>
                                                Delete Your Alias
                                            </Button>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Card>
                            </>
                        )}
                        <Card className="mt-3">
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>
                                    <strong>Right-Click Menu</strong>
                                </Accordion.Header>
                                <Accordion.Body as={Card.Body}>
                                <p>Arrange items in the left column as you'd like them to appear in the right-click menu.</p>
                                <Row>
                                    <Col md={6}>
                                    <h5>Current Right-Click Menu Setting</h5>
                                    
                                    </Col>
                                    <Col md={6}>
                                    <h5>Searchables Eligible for Right-Click Menu</h5>
                                    
                                    </Col>
                                </Row>
                                <DragDropContext onDragEnd={onDragEnd}>
                <Row>
                    <Col>
                        <Droppable droppableId="droppable1">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {newRightClickMenuSettings.map((item, index) => (
                                        <Draggable key={index} draggableId={`droppable1-${index}`} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="draggable-box"
                                                >
                                                    {`${item[1]} (${item[0]})`}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Col>
                    <Col>
                        <Droppable droppableId="droppable2">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {searchablesEligibleForRightClickMenu.map((item, index) => (
                                        <Draggable key={index} draggableId={`droppable2-${index}`} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="draggable-box"
                                                >
                                                    {`${item[1]} (${item[0]})`}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Col>
                </Row>
            </DragDropContext>
            <Button
                        variant="primary"
                        onClick={handleSave}
                        disabled={isSaveButtonDisabled}
                        className="mt-3"
                    >
                        Save
                    </Button>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Card>
                        <Card className="mt-3">
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><strong>Delete Account</strong></Accordion.Header>
                                <Accordion.Body>
                                    <h4 className="font-weight-bold">This action cannot be undone.</h4>
                                    <p>Deleting your account will remove all your data permanently.</p>
                                    <Button variant="danger" onClick={handleDeleteAccount}>
                                        Delete Your Account
                                    </Button>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Card>
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
}

export default SettingsPage;