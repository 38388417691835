import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { PencilFill } from 'react-bootstrap-icons';
import './EditableCell.css'; // Ensure this CSS includes the new styles for hover effect

export const EditableButtonCell = ({ shortlink, onDelete }) => {
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => setIsEditing(true);

    const handleCancelClick = () => {
        setIsEditing(false);
    };

    return (
        <td className="editable-cell" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', verticalAlign: 'middle' }}>
            {isEditing ? (
                <div className="edit-mode" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <IconButton color="error" size="small" onClick={() => onDelete(shortlink.shortlink)}>
                        <DeleteIcon />
                    </IconButton>
                    <Button variant="secondary" size="sm" onClick={handleCancelClick}>Cancel</Button>
                </div>
            ) : (
                <div className="view-mode" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <IconButton color="error" size="small" disabled>
                        <DeleteIcon />
                    </IconButton>
                    <button className="edit-button" onClick={handleEditClick}><PencilFill className="pencil-icon"/></button>
                </div>
            )}
        </td>
    );
};